import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet";
import Intro from '../components/Intro';
import Post from '../components/Post';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import '../styles/code.css';

const When = styled.h3`
  font-size: ${styles.fontSizes.xSmall};
  font-weight: ${styles.fontWeights.regular};
  margin-top: ${styles.spaces.xxxSmall};
  margin-bottom: ${styles.spaces.medium};
  color: ${styles.colors.quiet.dark};
  text-transform: uppercase;
  @media ${styles.breakpoints.mobile} {
    font-size: ${styles.fontSizes.xxSmall};
  }
`;


const Container = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  padding-top: ${styles.spaces.medium};
  padding-right: ${styles.spaces.medium};
  padding-bottom: ${styles.spaces.medium};
  padding-left: ${styles.spaces.medium};
  border-bottom: 0;
  border-top: 1px solid ${styles.colors.quiet.light};
  & a {
    font-size: ${styles.fontSizes.large};
    font-family: ${styles.fontFamilies.system};
    font-weight: ${styles.fontWeights.bold};
    color: ${styles.colors.ash.base};
    -o-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -khtml-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  & a:hover {
    // color: ${styles.colors.primary.base};
  }
  & a.pro-icon-next {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    padding: 10px 50px 10px 10px;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    border: 0px solid transparent;
  }
  & a.pro-icon-next:before {
    content: "\f061";
    position: absolute;
    right: 10px;
    padding: 5px 0px;
    font-size: 30px;
    font-family: "Font Awesome 5 Free";
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  & a.pro-icon-next:hover:before {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  & a.pro-icon-next:hover {
    -o-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -khtml-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  & a.pro-icon-prev {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    padding: 10px 10px 10px 50px;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    border: 0px solid transparent;
  }
  & a.pro-icon-prev:before {
    content: "\f060";
    position: absolute;
    left: 0px;
    padding: 5px 10px;
    font-size: 30px;
    font-family: "Font Awesome 5 Free";
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  & a.pro-icon-prev:hover:before, .pro-icon-prev:focus:before, .pro-icon-prev:active:before {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
  & a.pro-icon-next:hover {
    -o-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -khtml-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  @media ${styles.breakpoints.tabletPortrait} {
    padding-right: ${styles.spaces.small};
    padding-left: ${styles.spaces.small};
    a.pro-icon-next {
      font-size: ${styles.fontSizes.medium};
    }
    a.pro-icon-prev {
      font-size: ${styles.fontSizes.medium};
    }
    a.pro-icon-next:before {
      padding: 4px 10px 4px ;
      font-size: 20px;
    }
    a.pro-icon-prev:before {
      padding: 4px 20px 4px ;
      font-size: 20px;
    }
  }
  @media ${styles.breakpoints.mobile} {
    padding-right: 0;
    padding-left: 0;
    a.pro-icon-next {
      font-size: ${styles.fontSizes.xSmall};
    }
    a.pro-icon-prev {
      font-size: ${styles.fontSizes.xSmall};
    }
    a.pro-icon-next:before {
      padding: 2px 10px;
      font-size: 20px;
    }
    a.pro-icon-prev:before {
      padding: 2px 20px;
      font-size: 20px;
    }
  }

`;

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
        />

        <div>
          <Helmet title={`${post.frontmatter.title} | ${siteTitle}`} />
          <Intro dark bg='#161616' title={post.frontmatter.title} >
          {/* <Intro dark bg='#161616' bgimage={post.frontmatter.featuredImage.childImageSharp.sizes.src} title={post.frontmatter.title} > */}
            <When>{post.frontmatter.date}</When>
            <p>{post.frontmatter.subtitle}</p>
          </Intro>
          <Post>
          <div className="notch">
            <div className="blog-post">
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </div>
            </div>
          </Post>
         </div>

        <Container>
        <div  className="notch">
            {next && (
              <Link to={next.frontmatter.path} rel="next" className='pro-icon-prev'>
                {next.frontmatter.title}
              </Link>
            )}
        </div>
        <div  className="notch">
          {previous && (
            <Link to={previous.frontmatter.path} rel="prev" className='pro-icon-next'>
              {previous.frontmatter.title}
            </Link>
          )}
        </div> 
        </Container>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp{
            sizes(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpSizes
              src
            }
          }
        }
        path
        title
        subtitle
      }
    }
  }
`

import React from 'react';
import styled, {keyframes} from 'styled-components';
import styles from '../../styles/styles.json';
import PropTypes from 'prop-types';
import Link from "gatsby-link";


const Header = styled.div`
  // padding-right: env(safe-area-inset-right);
  // padding-left: env(safe-area-inset-left);
  // padding-top: ${styles.spaces.xSmall};
  // padding-bottom: ${styles.spaces.xSmall};
  // padding-right: ${styles.spaces.xSmall};
  // padding-bottom: 0;
  // padding-left: ${styles.spaces.xLarge};
  display: flex;
  // border: 1px solid white;
  padding: 0 !important;
  align-items: center;
  flex-wrap: wrap;
  background: #161616;
  // background: #ff0000;
  width: 100%;
  z-index: 999;
  position: fixed;
  & ul, li a{
    padding: 0
    margin: 0
    padding-right: ${styles.spaces.xSmall};
  }
  @media ${styles.breakpoints.tabletPortrait} {
    // padding-top: ${styles.spaces.medium};
    // padding-bottom: ${styles.spaces.large};
    // padding-right: ${styles.spaces.large};
    // padding-left: ${styles.spaces.large};
    // justify-content: center;
    // background: green;
  }
  @media ${styles.breakpoints.mobile} {
    // padding-top: ${styles.spaces.medium};
    // padding-bottom: ${styles.spaces.medium};
    // padding-right: ${styles.spaces.medium};
    // padding-left: ${styles.spaces.medium};
    flex-wrap: nowrap;
    // flex-direction: column;
    // background: red;
  }
`;


const Title = styled.div`
  flex: 1;
  white-space: nowrap;
  padding-left: ${styles.spaces.medium};
  // border: 1px solid green;
  @media ${styles.breakpoints.mobile} {
    display: none;
  }
  & a {
    font-weight: ${styles.fontWeights.semiBold} !important;
    display: flex;
    max-width: 0;
    align-self: center;
    font-family: ${styles.fontFamilies.system};
    font-size: ${styles.fontSizes.xSmall};
    text-transform: none;
    // letter-spacing: 1px;
    color: rgba(255,255,255,1);
    &:hover {
      color: rgba(255,255,255,1);
  }
  & .selected .logo path {
    fill: ${styles.colors.white};
  }
  @media ${styles.breakpoints.tabletPortrait} {
    & a {
      // transform: scale(0.75);
    };
    // font-size: ${styles.fontSizes.medium};
  }

`;

const Navigation = styled.ul`
  display: flex;
  align-items: center;
  // justify-content: space-between;
  // border: 1px solid green;
  padding-right: ${styles.spaces.xSmall};
  padding-bootom: 0;
  margin-bootom: 0 !important;
  // background: pink;

  @media ${styles.breakpoints.mobile} {
    padding-right: 0;
    flex-grow: 1;
    align-items: stretch;
  }

  & li {
    padding: 0 ${styles.spaces.small};
    align-self: center;
    display: flex;
    marging; 0;
    flex: 1;
    // background: pink;
  }
  
  & a {
    // border: 1px solid green;
    padding-top: ${styles.spaces.xSmall};
    padding-bottom: ${styles.spaces.xSmall};
    // background: red;
    display: flex;
    justify-content: center;
    align-self: center;
    font-family: ${styles.fontFamilies.system};
    font-weight: ${styles.fontWeights.semiBold} !important;
    font-size: ${styles.fontSizes.xSmall};
    text-transform: none;
    // letter-spacing: 1px;
    color: rgba(250,250,250,0.3);
    &:hover {
      color: rgba(255,255,255,1);
    }
    &.selected{
      color: rgba(255,255,255,1);
    }
    @media ${styles.breakpoints.tabletPortrait} {
      font-size: ${styles.fontSizes.small};
    }
    @media ${styles.breakpoints.mobile} {
      // font-size: ${styles.fontSizes.xSmall};
      margin: auto;
    }
  }
`;



const isExactActive = ({
  isPartiallyCurrent
}) => {
  return isPartiallyCurrent
    ? { className: "selected" }
    : null
}


const isPartiallyActive = ({
  isPartiallyCurrent
}) => {
  return isPartiallyCurrent
    ? { className: "selected" }
    : null
}

const PartialNavLink = props => (
  <Header>
    <Title style={{ margin: 0 }}>
      <Link to="/"  exact={true} activeClassName="selected">
        Javier Alvear
      </Link>
    </Title>
    <Navigation>
      {/* {links.map(link => ( */}
        {/* <li key={link.name}>
          <Link to={link.url} activeClassName="selected">{link.name}</Link>
        </li> */}
        <li>
          <Link to="/"  exact={true} activeClassName="selected">Work</Link>
        </li>
        <li>
          <Link to="/blog" getProps={isPartiallyActive} {...props}>Blog</Link>
        </li>
        <li>
          <Link to="/about" getProps={isPartiallyActive} {...props}>About</Link>
        </li>
      {/* ))} */}
    </Navigation>
  </Header>
)


PartialNavLink.propTypes = {
  siteTitle: PropTypes.string,
  name: PropTypes.string,
  linkClass: PropTypes.string,
};

export default PartialNavLink

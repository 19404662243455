/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */


 
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from './LayoutFooter';
import Header from "./LayoutHeader"
// import "./layout.css"
import { createGlobalStyle } from "styled-components"



import fonts from '../styles/fonts';
import reset from '../styles/reset';
import styles from '../styles/styles.json';


const GlobalStyle = createGlobalStyle`
${reset}
${fonts}
body {
  background-color: ${styles.colors.ash.base};
  color: ${styles.colors.ash.base};
  font-family: ${styles.fontFamilies.system};
  font-size: ${styles.fontSizes.small};
}
`

const LayoutProject = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteHomeQueryProject {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
        <GlobalStyle />
        <main> <Header
      siteTitle={data.site.siteMetadata.title}
      links={[
        {name: 'Work', url: '/'},
        {name: 'Blog', url: '/blog'},
        {name: 'About', url: '/about'},
      ]}
    />
    {children}</main>
        <Footer />
    </>
  )
}

LayoutProject.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutProject

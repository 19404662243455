import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import PropTypes from 'prop-types';
import Title2 from './Title2';
import Wrap from '../components/Wrap';

const Container = styled.div`
  background: white;
  margin: 0px;
  padding-top: ${styles.spaces.xLarge};
  padding-bottom: ${styles.spaces.xLarge};
  @media ${styles.breakpoints.mobile} {
    padding-top: ${styles.spaces.medium};
    padding-bottom: ${styles.spaces.medium};
  }
`;

class PostComponent extends React.Component {
  render() {
    return (
      <Container>
        <Wrap>
          <Title2 title={this.props.title} />
          {this.props.children}
        </Wrap>
      </Container>
    );
  }
}

PostComponent.propTypes = {
  title: PropTypes.string
};

export default PostComponent
